<template>
  <div
    v-for="(row, index) in number_of_rows"
    :key="index"
    class="skeleton-row"
    :class="main_class"></div>
</template>
<script>
export default {
  props: {
    mainClass: { type: String, default: undefined },
    numberOfRows: { type: Number, default: 1 },
  },
  setup(props) {
    return { main_class: props.mainClass, number_of_rows: props.numberOfRows };
  },
};
</script>