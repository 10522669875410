<template>
  <template v-if="should_show_content">
    <slot></slot>
  </template>
  <div
    v-else-if="!should_force_hide_skeleton"
    :class="skeleton_class"
    :style="customStyle">
    <SkeletonRow
      v-if="skeleton_type == SkeletonTypes.skeletonRow"
      :number-of-rows="number_of_elements"
      :main-class="skeleton_item_class" />
  </div>
</template>
<script>
import SkeletonRow from './SkeletonRow.vue';
import SkeletonTypes from 'CommonUtils/schemas/skeletonTypes';
import { toRefs } from 'vue';
export default {
  components: {
    SkeletonRow,
  },
  props: {
    skeletonClass: { type: String, default: undefined },
    skeletonItemClass: { type: String, default: undefined },
    numberOfElements: { type: Number, default: 1 },
    shouldForceHideSkeleton: { type: Boolean, default: false },
    shouldShowContent: { type: Boolean, default: false },
    skeletonType: { type: String, default: SkeletonTypes.skeletonRow },
    customStyle: {type: String, required:false, default: () => '' }
  },
  setup(props) {
    const { shouldShowContent, shouldForceHideSkeleton } = toRefs(props);
    
    return {
      skeleton_item_class: props.skeletonItemClass,
      skeleton_class: props.skeletonClass,
      number_of_elements: props.numberOfElements,
      should_show_content: shouldShowContent,
      should_force_hide_skeleton: shouldForceHideSkeleton,
      skeleton_type: props.skeletonType,
      SkeletonTypes,
    };
  },
};
</script>
